/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import { useUIDSeed } from 'react-uid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import CTA from '../../../SubComponents/CTA/CTA';
import fiveUpCss from './FiveUp.module.css';
import universalHandleClick from '../../../UniversalHandleClick';
import StyledButton from '../../../../../EnterpriseDesignSystem/Button/StyledButton';
import cmsCssClassesToString from '../../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';

const FiveUp = ({
    data, salesforceResponse,
}) => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const seed = useUIDSeed();
    useStyles(fiveUpCss);

    if (data.tiles?.reference?.length) {
        const {
            ga_constants = {},
            tiles_5up_ctaonly = [],
            tiles_5up_standard_headingcta = [],
        } = data.tiles.reference[0] || {};

        const {
            tile = [], mobile_view_all: mobileData = {}, cta_style = {},
            cta_position = {},
        } = tiles_5up_ctaonly[0] || tiles_5up_standard_headingcta[0] || {};

        const ctaPosition = cta_position.presentation_variant;
        const ctaStyle = cta_style.presentation_variant;
        let primaryTile = tile;
        if (isMobile && mobileData.cta?.link?.href) {
            primaryTile = [...tile, mobileData];
        }
        const handleClick = universalHandleClick(salesforceResponse);
        return (
            <div className={fiveUpCss.container}>
                {
                    primaryTile.map(({
                        image = {}, cta = {}, content = {}, heading = '', styled_button = [],
                    }) => (
                        <div key={seed(image)} className={`${fiveUpCss.imageWrapper}`} role="presentation" onClick={() => handleClick(cta, ga_constants)}>
                            <img className={fiveUpCss.image} src={image.url} alt={image.description} />
                            {content?.heading?.text
                                ? <h4 className={cmsCssClassesToString(content?.heading?.css_classes)}>{content.heading.text}</h4>
                                : heading && <div className={fiveUpCss.heading}>{heading}</div>}
                            {/* <StyledButton /> */}
                            {styled_button.length > 0
                                ? (
                                    <div className={`${fiveUpCss.flexCenter}`}>
                                        <StyledButton label={styled_button[0].link.title} link={styled_button[0].link.href} variant={styled_button[0].variant} />
                                    </div>
                                )
                                : cta.link?.href
                                && (
                                    <div className={ctaPosition === 'internal_cta' ? fiveUpCss.overlay : fiveUpCss.ctaContainer}>
                                        <CTA
                                            data={
                                                {
                                                    link: cta.link,
                                                    theme: '',
                                                    presentation: ctaStyle,
                                                    tracking_event_category: ga_constants.tracking_event_category,
                                                    tracking_event_action: ga_constants.tracking_event_action,
                                                    tracking_event_label: cta.tracking_event_label,
                                                }
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    ),
                    )
                }
            </div>
        );
    }

    return mbpLogger.logError({
        appName: process.env.npm_package_name,
        component: 'Tiles/FiveUp/FiveUp.js',
        message: 'No data available to render component',
    });
};

FiveUp.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
FiveUp.defaultProps = {
    salesforceResponse: {},
};

export default FiveUp;

/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import useClickStreamCustomEventsTracking from '../../../helpers/Personalization/useClickstreamCustomEventsTracking';

export default function universalHandleClick(salesforceResponse) {
    const dispatch = useDispatch();
    const history = useHistory();
    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: salesforceResponse?.campaign,
        page: { type: 'home' },
    });
    const handleClick = (item, gaConstants = {}) => {
        dispatch(trackEvent({
            eventCategory: item?.tracking_event_category ||  gaConstants?.tracking_event_category || '',
            eventAction: item?.tracking_event_action ||  gaConstants?.tracking_event_action || '',
            eventLabel: item?.tracking_event_label || '',
        }));
        const path = item?.link?.href;
        if (path?.includes('://')) {
            window.location.href = path;
        } else {
            history.push(path);
        }
        trackClickThrough();
    };
    return handleClick;
}

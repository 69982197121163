/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import loadable from '@loadable/component';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './Medium.module.css';
import ResponsiveWrapper from '../../../../../SubComponents/ResponsiveWrapper';
import universalHandleClick from '../../../../../UniversalHandleClick';

const MessageStandard = loadable(() => import(/* webpackChunkName: "MessageStandard" */ '../../../../../SubComponents/Message/MessageStandard'));

const MediumBanner = ({ data, salesforceResponse }) =>     {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'Banner/1up/Standard/Medium/Medium.js',
            message: 'No entries provided for MediumBanner',
        });
    }
    useStyles(styles);
    const handleClick = universalHandleClick(salesforceResponse);
    const backgrounds = {
        desktop: data.background_image?.desktop?.url,
        tablet: data.background_image?.tablet?.url, // can add fallbacks easily this way
        mobile: data.background_image?.mobile?.url,
    };
    const isBackground = data?.message?.has_background;
    const device = (matches) => Object.keys(matches).find((key) => matches[key]); // returns device type string
    const imageQuality = '?auto=webp';
    const renderBanner = (matches) => (
        <>
            <div style={{ backgroundImage: `url(${backgrounds[device(matches)]}${imageQuality})` }} className={`${styles.mediumBannerContainer} ${isBackground ? styles.messageBg : ''}`}>
                {device(matches) === 'mobile' && isBackground ? '' : <MessageStandard data={data?.message} />}
                {backgrounds[device(matches)] && <img src={`${backgrounds[device(matches)]}${imageQuality}`} alt="background image" height="0" width="0" />}
            </div>
            {device(matches) === 'mobile' && isBackground && <MessageStandard data={data?.message} />}
        </>
    );

    return (
        <div data-testid="mediumBanner" role="presentation" className={styles.bannerContainer} onClick={() => handleClick(data?.message?.cta?.[0])}>
            <ResponsiveWrapper renderer={renderBanner} />
        </div>
    );
};
MediumBanner.propTypes = {
    data: object.isRequired,
    salesforceResponse: object,
};
MediumBanner.defaultProps = {
    salesforceResponse: {},
};
export default MediumBanner;
